import { Box, Container, Flex, Grid, GridItem, useMediaQuery } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';
import { withTranslations } from 'store/translations';

import { LinkType } from '../../lib/enums/LinkType';
import { OverviewItemModel } from '../../utils/types/overviewItemModel';
import { Link } from '../base/Link';
import { OverviewItem } from './OverviewItem';

interface OverviewSpotAutomaticNewsProps {
  newsItems: OverviewItemModel[];
}

export const OverviewSpotAutomaticNews: FunctionComponent<OverviewSpotAutomaticNewsProps> = ({ newsItems }) => {
  const { common, multiple } = withTranslations();
  const router = useRouter();
  // const locale = router.locale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE! ? '' : `/${router.locale}`;

  const [isSmallScreen] = useMediaQuery('(max-width: 768px)');

  return (
    <Container>
      <Flex flexDir='column' py={{ base: 'm', md: 'l' }} w='100%'>
        <Flex flexDir={'row'} alignItems='center' justifyContent='space-between' gap='xs' pb={{ base: 's', lg: 'l' }}>
          <Box textStyle={{ base: 'desktop.body.l', md: `desktop.h5` }}>{common('latestNews')}</Box>
          <Link
            href={multiple('news')}
            type={LinkType.SecondaryButton}
            iconName='chevronRightForLink'
            locale={router.locale}
          >
            {common('readMore')}
          </Link>
        </Flex>

        {newsItems.length < 3 || !isSmallScreen ? (
          <Grid
            templateColumns={{
              base: 'repeat(1, 1fr)',
              lg: `repeat(3, 1fr)`,
            }}
            gap={{ base: 'xs', md: 'l' }}
          >
            {newsItems.map((item) => (
              <GridItem key={item.id}>
                <OverviewItem model={item} />
              </GridItem>
            ))}
          </Grid>
        ) : (
          <Flex
            flexDir={'row'}
            pb='s'
            overflow='auto'
            gap='s'
            sx={{
              '::-webkit-scrollbar': {
                bg: 'white',
                height: '3px',
              },
              '::-webkit-scrollbar-thumb': {
                bg: 'cyan.web',
              },
            }}
          >
            {newsItems.map((item, index) => (
              <GridItem key={index} minW={'73%'}>
                <OverviewItem model={item} />
              </GridItem>
            ))}
          </Flex>
        )}
      </Flex>
    </Container>
  );
};
