import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, GridItem } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { useRouter } from 'next/router';
import { normalize } from 'pages/api/sitemap';
import React, { FC, useEffect, useState } from 'react';
import { withTranslations } from 'store/translations';

import { LinkType } from '../../lib/enums/LinkType';
import { ContentPage, ManualOverviewItem as ManualOverviewItemModel } from '../../models';
import { IconComp } from '../base/IconComp';
import { Link } from '../base/Link';

export const OverrideItem: FC<{ model: ManualOverviewItemModel }> = ({ model }) => {
  const router = useRouter();
  const translations = withTranslations();
  const [items, setItems] = useState<
    {
      ctaText?: string | undefined;
      ctaUrl?: any;
      ctaOpenInNewTab?: boolean | undefined;
      headline: string;
      description: string;
    }[]
  >();
  const fetchItems = async () => {
    const externalUrl = model.elements.ctaSnippetUrl.value;
    const kontentAiItem = model.elements.ctaSnippetKontentAiUrl.value[0];
    const fileFrontify = JSON.parse(model.elements.ctaSnippetFrontifyFile.value);
    const setUrl = async () => {
      if (fileFrontify) return fileFrontify.downloadUrl as string;
      if (externalUrl) return externalUrl;
      if (kontentAiItem) {
        const item = (await fetch(`/api/sitemap?codename=${kontentAiItem}&locale=${router.locale}`)
          .then((res) => res.json())
          .catch(() => null)) as ContentPage | null;
        return item ? Object.keys(normalize(item, router.locale, undefined, true, translations))?.[0] : '';
      }
    };
    //   return {
    //     headline: model.elements.headline.value,
    //     description: model.elements.description.value,
    //     ...((externalUrl || fileFrontify || kontentAiItem) && {
    //       ctaText: model.elements.ctaSnippetCtaText.value,
    //       ctaUrl: await setUrl(),
    //       ctaOpenInNewTab: model.elements.ctaSnippetOpenIn.value[0].codename === 'new_tab',
    //     }),
    //   };

    setItems([
      {
        headline: model.elements.headline.value,
        description: createRichTextHtmlResolver(nodeParser).resolveRichText({
          element: model.elements.description,
        }).html,
        ...((externalUrl || fileFrontify || kontentAiItem) && {
          ctaText: model.elements.ctaSnippetCtaText.value,
          ctaUrl: await setUrl(),
          ctaOpenInNewTab: model.elements.ctaSnippetOpenIn.value[0].codename === 'new_tab',
        }),
      },
    ]);
  };

  useEffect(() => {
    fetchItems();
  }, []);
  useEffect(() => {
    fetchItems();
  }, [router.locale]);
  const data = {
    items,
  };
  return (
    <>
      <GridItem maxW='380px'>
        <Accordion allowToggle w='100%' flexDirection={'column'}>
          {data.items?.map((item, index) => (
            <AccordionItem
              key={index}
              borderTop={'1px solid transparent'}
              borderBottom={'1px solid'}
              borderBottomColor={'mountain.20'}
              pb='xs'
              pt={index && 's'}
              w='100%'
            >
              {({ isExpanded }) => {
                return (
                  <>
                    <AccordionButton
                      textAlign='start'
                      as={'button'}
                      _hover={{ cursor: 'pointer' }}
                      justifyContent='space-between'
                      p='0'
                      textStyle={{ base: 'desktop.body.book.s', md: 'desktop.body.book.l' }}
                      w='100%'
                    >
                      {item.headline}
                      <Flex ml='xs'>
                        <IconComp styleName='active' iconName={isExpanded ? 'minus' : 'plus'} />
                      </Flex>
                    </AccordionButton>
                    <AccordionPanel textStyle={{ base: 'desktop.body.book.s', md: 'desktop.body.l' }} p='0'>
                      <Flex
                        mt='xs'
                        flexDir='column'
                        gap='xs'
                        sx={{
                          a: {
                            color: 'cyan.ramboll',
                            textDecor: 'underline',
                          },
                          'ul, ol': {
                            listStylePosition: 'inside',
                          },
                        }}
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                      {item.ctaUrl && (
                        <Box mt='xs'>
                          <Link
                            type={LinkType.SecondaryButton}
                            href={item.ctaUrl}
                            isExternal={item.ctaOpenInNewTab}
                            iconName='chevronRightForLink'
                            locale={router.locale}
                          >
                            {item.ctaText}
                          </Link>
                        </Box>
                      )}
                    </AccordionPanel>
                  </>
                );
              }}
            </AccordionItem>
          ))}
        </Accordion>
      </GridItem>
    </>
  );
};
